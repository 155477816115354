<template>
  <OnlineReportageEditViewVlm disabled />
</template>

<script>
import OnlineReportageEditViewVlm from '@/views/onlineReportage/vlm/OnlineReportageEditViewVlm'

export default {
  name: 'InfoboxView',
  components: {
    OnlineReportageEditViewVlm
  }
}
</script>
